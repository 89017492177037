@import "../../../../variables";

.container {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
}

.chartWrap {
  display: flex;
  height: 496px;
}
