.container {
  padding-right: 28px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.containerLoader{
  padding-right: 28px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
