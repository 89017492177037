@import '../../../../variables';

.body {
  padding:0 40px 50px;
  height: calc(100vh - 300px);
  overflow: auto;
}

.tab {
  color: $oxford-blue;
  font-size: 14px;
  padding: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.activeTab {
  color: $ribbon-blue;
  font-size: 14px;
  padding: 4px;
  cursor: pointer;
  border-bottom: 1px solid $ribbon-blue;
  white-space: nowrap;
}

.tabs {
  margin: 20px 24px 10px;
  display: flex;
  gap: 28px;
  position: relative;
}

.iconWrap {
  cursor: pointer;
  display: flex;
}

.addButton {
  border: 1px solid rgba(22, 88, 243, 1);
  height: 42px;
  min-width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.wrapper {
  padding-top: 16px;
  margin-bottom: 16px;
  box-shadow: 0px -1px 0px rgba(53, 64, 82, 0.1);
}

.wrapper:first-child {
  box-shadow: none;
}

.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 24px;

  &.dropdownOneItem {
    justify-content: stretch;
  }

  & div:first-child {
    width: calc(100% - 82px);
  }

  & > .iconWrap {
    margin-bottom: 8px;
  }

  & p {
    margin: 0 0 8px;
  }
}
