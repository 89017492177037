.checkboxes {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
  flex-wrap: wrap;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-width: 380px
}
