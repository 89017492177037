@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  text-decoration: none;
}

p,
div,
span,
a {
  font-family: 'Lato', sans-serif;
}

button:focus {
  outline: none !important;
}

a[href^='/admin/bot'] {
  text-decoration: none !important;
}

.Connect-MainMenu--content-11 {
  width: 2% !important;
}

.MuiFormControl-marginNormal-164 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.MuiTypography-body1-81 {
  padding: 0 !important;
  height: 100%;
}

.MuiTypography-body1-386 {
  padding: 0 !important;
  height: 100%;
}

.MuiTypography-body1-418 {
  padding: 0 !important;
  height: 100%;
}

#swipeable > div > div > div {
  padding: 0 !important;
  height: 100%;
}

.main {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  background: #f7fafe;
}

.row--main {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
}

.title--small {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #3a3f62;
}

.title--main {
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 16px;
}

.title-section {
  color: #3a3f62;
  height: 100%;
  background-size: contain !important;
  box-shadow: 0 2px 23px rgba(0, 0, 0, 0.0593014);
  z-index: 1;
}

.title-section .title--small {
  font-size: 16px;
  color: #616581;
  font-weight: normal;
}

.section__inner {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 0;
  position: relative;
}

.login-section {
  height: 100%;
  background: #ffffff;
}

.title-section .section__inner {
  padding: 64px;
  align-items: flex-start;
  justify-content: flex-end;
}

.fa-google-plus-g {
  font-size: 18px;
  font-weight: 700;
}

.button-signin:not(.button-small):not(.button--nostyles):hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.button-signin {
  background: #1658f3;
  box-shadow: 0 3px 9px rgba(19, 69, 186, 0.206267);
  border-radius: 10px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin: 8px 0;
  outline: none;
  border: none;
  padding: 16px 24px;
  transition: -webkit-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

.button--google {
  background: #f34a38;
  box-shadow: 0 3px 9px rgba(211, 72, 54, 0.206267);
  border-radius: 10px;
  color: #ffffff;
}

.button--google span {
  line-height: 18px;
}

.button--nostyles {
  color: #616581;
  padding: 0;
  background: transparent;
  margin-top: 0;
}

.button--nostyles:hover {
  text-decoration: underline;
}

.button-small {
  position: absolute;
  bottom: 18px;
  right: 24px;
  font-size: 12px;
  color: #3a3f62;
  margin: 0;
  height: 42px;
  background: #ffffff;
}

.button-signup {
  margin: 0 0 0 4px;
  color: #1658f3;
  font-size: 14px;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.form--login {
  margin: 32px 0;
  text-align: center;
  max-width: 60% !important;
}

.form-input {
  margin: 10px 0 24px;
  border: 1px solid #d7d8df;
  box-sizing: border-box;
  border-radius: 10px;
  padding-left: 24px;
  padding-right: 24px;
  height: 46px;
  outline-color: #1658f3;
  width: 100%;
}

.form-input::-webkit-input-placeholder {
  color: #b0b2c0;
  font-size: 14px;
  font-weight: 300;
}

.form-input:-ms-input-placeholder {
  color: #b0b2c0;
  font-size: 14px;
  font-weight: 300;
}

.form-input::placeholder {
  color: #b0b2c0;
  font-size: 14px;
  font-weight: 300;
}

.input-label {
  font-size: 14px;
  color: #616581;
  margin: 0;
  font-weight: 300;
}

.platforms {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: absolute;
  bottom: 30px;
}

.platforms-soon {
  margin-left: auto;
}

.platforms__image {
  max-height: 100%;
}

.platforms__title {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
}

.m-l-24px {
  margin-left: 24px;
}

@media (min-width: 992px) {
  .main {
    padding: 50px 90px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main {
    padding: 30px 60px !important;
  }

  .platforms {
    flex-direction: column;
    justify-content: flex-start;
  }

  .platforms-soon {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .row--main {
    height: 100%;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  main {
  }

  .row--main {
    height: 50%;
  }

  .col-sm-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 576px) {
  .col-sm-10 {
    flex: 0 0 70% !important;
  }
}

.delay-_05s {
  /*animation-delay: 0.05s;*/
}

.delay-_1s {
  /*animation-delay: 0.1s;*/
}

.delay-_15s {
  /*animation-delay: 0.15s;*/
}

.delay-_2s {
  /*animation-delay: 0.2s;*/
}

.delay-_25s {
  /*animation-delay: 0.25s;*/
}

mark {
  border-radius: 50px !important;
  padding: 1px 8.4px !important;
  background: #5a98f7 !important;
  font-size: 14px !important;
  font-family: 'Lato', sans-serif;
  color: white;
  /*white-space: nowrap;*/
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9a9fa8;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #9a9fa8;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #9a9fa8;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #9a9fa8;
}

body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
  margin-left: 8px;
}

.react-autosuggest__input {
  width: 130px;
  height: 38px;
  padding: 2px 8px;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid hsl(0, 0%, 90%);
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  box-shadow: 0 4px 8px rgba(53, 64, 82, 0.32);
  width: 130px;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  z-index: 2;
  border-radius: 4px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(90, 152, 247, 0.1);
}

.footer {
  margin: 9px 20px;
  font-size: 12px;
  color: #777;
}

/* Inspirated from  http://www.alessioatzeni.com/wp-content/tutorials/html-css/CSS3-loading-animation-loop/index.html */
.Loader-small {
  text-align: center;
}

.ouro {
  position: relative;
  display: inline-block;
  height: 46px;
  width: 46px;
  margin: 1em;
  border-radius: 50%;
  background: none repeat scroll 0 0 #dddddd;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 0 25px rgba(0, 0, 255, 0.075);
}

.ouro:after {
  content: '';
  position: absolute;
  top: 9px;
  left: 9px;
  display: block;
  height: 28px;
  width: 28px;
  background: none repeat scroll 0 0 #f2f2f2;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ouro > span {
  position: absolute;
  height: 100%;
  width: 50%;
  overflow: hidden;
}

.left {
  left: 0;
}

.right {
  left: 50%;
}

.anim {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 999px;
  background: linear-gradient(
    285.2deg,
    rgb(90, 152, 247) -35.9%,
    rgb(22, 88, 243) 61.17%
  );
  /*background: none repeat scroll 0 0 #1658F3;*/
  opacity: 0.8;
  -webkit-animation: ui-spinner-rotate-left 3s infinite;
  animation: ui-spinner-rotate-left 3s infinite;
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
}

.left .anim {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.right .anim {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  left: -100%;
  -webkit-transform-origin: 100% 50% 0;
  transform-origin: 100% 50% 0;
}

/* v2 */
.ouro2 .anim {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.ouro2 .right .anim {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

/* v3 */
.ouro3 .anim {
  -webkit-animation-delay: 0s;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-delay: 0s;
  animation-duration: 3s;
  animation-timing-function: linear;
}

.ouro3 .right .anim {
  -webkit-animation-name: ui-spinner-rotate-right;
  -webkit-animation-delay: 0;
  -webkit-animation-delay: 1.5s;
  animation-name: ui-spinner-rotate-right;
  animation-delay: 0;
  animation-delay: 1.5s;
}

/* round variation */
.round .ouro:after {
  display: none;
}

/* double variation */
.double .ouro:after {
  height: 13px;
  width: 13px;
  left: 7px;
  top: 7px;
  border: 10px solid #ddd;
  background: transparent;
  box-shadow: none;
}

@keyframes ui-spinner-rotate-right {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-left {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-right {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-left {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.styles_container__19QGg{margin:auto;display:flex;flex-direction:column;align-items:center;justify-content:center;padding:20px;height:100%}.styles_text__WubUC{font-size:14px;font-family:Lato,sans-serif;color:#616581;text-align:center;margin:16px 0 0}
.styles_checkboxes__2RnNI{display:flex;justify-content:space-evenly;margin-top:40px;flex-wrap:wrap}.styles_container__2sgyS{display:flex;flex-direction:column;justify-content:center;height:100%;min-width:380px}
.styles_container__1XSeP{height:100%;display:flex;align-items:center;justify-content:center}
.Loader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.Loader__spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #5a98f7;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.styles_gridItem__2fDgk{height:10px;width:100%;margin-bottom:5px}.styles_tooltip__25PRg{border:1px solid #ebebef;box-sizing:border-box;box-shadow:4px 4px 29px rgba(19,69,186,.0855129);border-radius:10px;z-index:9999;opacity:1;background:#fff}
.styles_gradientBarWrapper__1Hv4G{width:100%;display:flex;justify-content:center}.styles_gradientBar__3mP-7{display:flex;flex-flow:row nowrap;width:100%;max-width:340px;margin-top:30px}.styles_gradientBarColumn__3zvkM{width:20%;margin-right:5px}.styles_gradientBarColumn__3zvkM:last-child{margin-right:0}.styles_gradientBarItem__1q6jG{height:10px;width:100%;margin-bottom:8px}.styles_gradientBarItemText__3bt-V{color:#616581;font-size:12px;line-height:1;font-family:"Lato",sans-serif;text-align:right}
.styles_container__3wx1A{height:100%;display:flex;justify-content:center;flex-direction:column}
.DateControl {
  text-align: right;
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  min-height: 100px;
}

.frequency {
  text-align: right;
  margin-top: 16px;
  width: -webkit-max-content;
  width: max-content;
}

.frequency__button {
  background: #ffffff;
  border: 1px solid #ebebef;
  box-sizing: border-box;
  color: #616581;
  padding: 14px 18px;
  /*width: 90px;*/
  text-align: center;
  font-size: 14px;
  outline: none !important;
}
.frequency__button:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}
.frequency__button.active {
  background-color: #5a98f7;
  color: #ffffff;
  border-color: #5a98f7;
}

.frequency__button:first-of-type {
  border-radius: 10px 0 0 10px;
}

.frequency__button:last-of-type {
  border-radius: 0 10px 10px 0;
}

@media (max-width: 767px) {
  .frequency {
    display: flex;
    flex-wrap: wrap;
    outline: none;
    max-width: 300px;
    align-self: center;
    width: -webkit-max-content;
    width: max-content;
  }
  .frequency__button {
    flex-basis: 50%;
    min-width: 60px;
    padding: 14px 0;
  }

  .frequency__button:first-of-type {
    border-radius: 10px 0 0 0;
  }
  .frequency__button:nth-of-type(2) {
    border-radius: 0 10px 0 0;
  }
  .frequency__button:nth-of-type(3) {
    border-radius: 0 0 0 10px;
  }
  .frequency__button:last-of-type {
    border-radius: 0 0 10px 0;
  }
}

.styles_input__3JaEl{width:50%;height:32px;border-radius:10px;font-size:12px;text-align:center;margin:8px auto;border:1px solid #ebebef;outline-color:#5a98f7;cursor:pointer}.styles_dateRangePickerContainer__18jWL{text-align:center;width:300px;max-width:100%;margin:0 auto;transition:.2s ease-in-out}@media(max-width: 767px){.styles_dateRangePickerContainer__18jWL{width:100%}}
.styles_container__3v7ki{display:flex;justify-content:space-between;flex-flow:row nowrap;align-items:baseline;grid-gap:40px;gap:40px}
.styles_container__2stFP{display:flex;flex-direction:column;justify-content:flex-start;align-items:stretch;max-height:100%;padding:40px;background-color:#fff;box-shadow:4px 4px 29px rgba(19,69,186,.09);border-radius:10px;flex:1 1}.styles_header__f3DGF{display:flex;flex-direction:row;justify-content:space-between}.styles_text__30XYu{font-size:16px;line-height:1;font-family:"Lato",sans-serif;margin:0;color:#3a3f62}.styles_statsText__2W19b{font-size:12px;line-height:1;font-family:"Lato",sans-serif;margin:0;color:#3a3f62}.styles_statsNumber__1Fn_9{font-size:18px;line-height:1;color:#1658f3;font-weight:700;margin-left:8px;margin-right:8px}.styles_title__1cHmR{font-size:18px;font-weight:700;margin-right:16px}.styles_titleWrapper__2oa1j{margin-bottom:40px;display:flex;flex-wrap:wrap;align-items:center}.styles_button__34q6M{font-family:"Lato",sans-serif;cursor:pointer;font-weight:400;color:#1658f3}
.styles_counter__11pkW{width:100%;padding:20px;background-color:#fff;box-shadow:4px 4px 29px rgba(19,69,186,.09);text-align:center;border-radius:10px;min-width:170px}.styles_value__1ZQ5c{font-size:24px;margin:8px 0;font-weight:700;color:#1658f3}.styles_title__3aoIn{font-size:14px;margin:8px 0;font-weight:400;color:#616581}
.styles_container__12BeE{height:100%;display:flex;align-items:center;justify-content:center;width:300px}
.styles_button__1I3kT{font-family:"Lato",sans-serif;cursor:pointer;font-weight:400;color:#1658f3}
.styles_container__5S5Ag{padding-right:28px;display:flex;flex-direction:column;min-width:905px}.styles_containerLoader__3kEmu{padding-right:28px;display:flex;flex-direction:column;grid-gap:40px;gap:40px;height:100%}.styles_charts__2evO_{width:100%;display:flex;flex-direction:column;justify-content:space-between}.styles_chart__1oM-z{padding:40px;background-color:#fff;border-radius:10px;box-shadow:4px 4px 29px rgba(19,69,186,.0855129);width:49%;justify-content:flex-start;max-height:100%;display:flex;flex-direction:column}.styles_chart__1oM-z:not(:first-child){margin-left:40px}.styles_chart__1oM-z>div:not(.styles_DateControl__tILUv){min-height:calc(100% - 80px - 70px);margin-top:40px}.styles_chartFull__1rEOV{width:100%}.styles_row__MjfoD{width:100%;display:flex;flex-direction:row;justify-content:space-between;flex-grow:1;margin:auto 0 40px;grid-gap:40px;gap:40px}.styles_flexBlock__13bVf{display:flex;grid-gap:40px;gap:40px}.styles_flexBlockColumn__GW8J8{display:flex;grid-gap:40px;gap:40px;flex-direction:column}.styles_extendedChartBlock__1-UPM{width:70%}.styles_extendedRightBlock__3rshW{width:30%}.styles_namedCountersBlock__wzKqL{display:flex;flex-direction:row;justify-content:space-evenly}.styles_channelsCircleChartBlock__4GJqW{padding-left:40px}.styles_chartsColumn__3l5LL{width:100%;display:flex;flex-direction:column;align-items:flex-end;justify-content:space-between;flex-grow:1;margin:auto 0 40px}.chartjs-size-monitor{position:relative;max-height:100%}.chartjs-render-monitor{margin:0 auto;max-width:100%}
.styles_container__3dlVA{width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-grow:1}
.message-group {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 220px);
  width: 100%;
  padding: 40px;
}

.add-btn {
  right: 16px;
  bottom: 16px;
  position: absolute;
}

.atom-header {
  display: flex;
  align-items: center;
}

.dlt-msg {
  position: absolute;
  cursor: pointer;
  top: -10px;
  right: -20px;
  z-index: 5;
}

.MuiGrid-typeItem-135.MuiGrid-grid-xs-12-171.MuiGrid-grid-sm-8-180 {
  width: 60%;
  max-width: 60%;
}

/*.Select-menu-outer {
    top: auto!important;
    bottom: 100%!important;
}*/

.quick-reply {
  border-radius: 35px;
  color: #2668e8;
  outline: none;
  text-align: center;
  margin: 3px;
  border: 1px solid #2668e8;
  background-color: #ffffff;
  padding: 10px 16px 24px;
  font-size: 14px;
  line-height: 16px;
  resize: none;
  height: 36px;
}

.rep-btns-container {
  background-color: white;
  border-radius: 0 0 10px 10px;
}

.add-button {
  color: #0084ff;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  margin: auto;
  border-radius: 0 0 10px 10px;
}

.add-reply {
  margin: 0 0 5px;
  min-width: 186px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: 1px solid #1658f3;
  border-radius: 50px;
  font-size: 14px;
  line-height: 16px;
  height: 38px;
  display: flex;
}

.add-reply-text {
  padding: 10px;
  color: #1658f3;
}

.button {
  width: 100%;
  color: #0084ff;
  font-weight: 500;
  text-align: center;
  height: 36px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  outline: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  display: none !important;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 20px !important;
  height: 20px !important;
}

.rangeslider-horizontal {
  height: 10px !important;
}

.rep-container {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  margin-top: 10px;
}

.message {
  width: 280px;
  position: relative;
  border: 1px solid #e5e5e5;
  padding: 0;
  border-radius: 10px;
  margin: 0 10px;
  background-color: #f8f8f8;
}

.generic-container {
  margin-bottom: 20px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.generic-msg {
  min-width: 400px;
  margin: 5px;
  padding: 20px;
}

.add-element {
  color: grey;
  width: 100px;
  text-align: center;
  padding-top: 80px;
}

.NewMessage-paper-300 {
  max-width: 600px;
  min-width: 400px;
  width: auto !important;
}

.styles_container__21HZr{width:100%}
.styles_tooltip__1L4pX{box-sizing:border-box;box-shadow:4px 4px 29px rgba(19,69,186,.0855129);border-radius:10px;z-index:9999;opacity:1;text-align:start;font-weight:normal;padding:10px;background-color:#616581}
.styles_container__2e_rx{height:120px;position:relative}.styles_container__2e_rx:hover .styles_editLink__3MM0m{display:block;left:230px}.styles_container__2e_rx:hover .styles_iconsWrap__JWWaD{visibility:visible}.styles_input__1u3uO{border:1px solid #e5e5e5;height:120px;overflow:scroll;font-size:14px;padding:10px 10px 0;outline:none;background:#fff;cursor:auto}.styles_input__1u3uO a:hover{text-decoration:none}.styles_editLink__3MM0m{display:none;position:absolute;border-radius:20px;padding:5px 13px 13px 12px;height:40px;cursor:pointer;top:42px;right:-23;z-index:3;width:40px;background:#fff;border:1px solid #ebebef;box-sizing:border-box}.styles_parameter__RqYfD{background:#deeafd;border-radius:3px}.styles_linkEdited__3kyXj{cursor:pointer;color:blue;text-decoration:underline}.styles_iconsWrap__JWWaD{position:absolute;visibility:hidden;z-index:2;bottom:4px;right:4px;display:flex;grid-gap:2px;gap:2px}.styles_icon__1lSWi{width:24px;height:24px;border-radius:4px;background-color:rgba(53,64,82,.7);display:flex;align-items:center;justify-content:center;cursor:pointer;outline:none}
.styles_container__r9ZIP{position:absolute;background-color:#fff;padding:24px 0 0;outline:none;width:600px;border-radius:10px;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);overflow-y:visible}.styles_header__19RTk{padding:0 46px 0 24px}.styles_closeButton__3xbPG{position:absolute;top:24px;right:24px;cursor:pointer}.styles_title__38nJ7{font-weight:bold;font-size:24px;color:#354052;margin:0;word-break:break-all}.styles_footer__8CQNW{border-top:1px solid #c7cad6;padding:24px;display:flex;justify-content:space-between;flex-direction:row-reverse}.styles_buttons__25iXf{display:flex;grid-gap:8px;gap:8px}
.styles_body__2pdiX{padding:0 40px 50px;height:calc(100vh - 300px);overflow:auto}.styles_tab__qKrWJ{color:#354052;font-size:14px;padding:4px;cursor:pointer;white-space:nowrap}.styles_activeTab__8I-2_{color:#1658f3;font-size:14px;padding:4px;cursor:pointer;border-bottom:1px solid #1658f3;white-space:nowrap}.styles_tabs__MAWUW{margin:20px 24px 10px;display:flex;grid-gap:28px;gap:28px;position:relative}.styles_iconWrap__3akU6{cursor:pointer;display:flex}.styles_addButton__QthXy{border:1px solid #1658f3;height:42px;min-width:42px;display:flex;align-items:center;justify-content:center;border-radius:10px;cursor:pointer}.styles_wrapper__2bKOJ{padding-top:16px;margin-bottom:16px;box-shadow:0px -1px 0px rgba(53,64,82,.1)}.styles_wrapper__2bKOJ:first-child{box-shadow:none}.styles_container__3bUU1{display:flex;align-items:flex-end;justify-content:space-between;grid-gap:8px;gap:8px;margin-bottom:24px}.styles_container__3bUU1.styles_dropdownOneItem__3pJya{justify-content:stretch}.styles_container__3bUU1 div:first-child{width:calc(100% - 82px)}.styles_container__3bUU1>.styles_iconWrap__3akU6{margin-bottom:8px}.styles_container__3bUU1 p{margin:0 0 8px}
.styles_container__1Hw_R{border-radius:10px;border:1px solid #ebebef;width:100%;margin-bottom:10px;min-width:400px}.styles_container__1Hw_R:hover i{display:block}.styles_header__WJA6x{padding:17px 24px 24px 24px;font-weight:700;color:#354052;border-bottom:2px solid #ebebef;display:flex;justify-content:space-between}.styles_body__3QVZJ{padding:24px}.styles_deleteTemplate__3pjvT{right:28px;top:-16px;position:absolute;display:none;transition:all .5s ease}.styles_deleteIcon__3exle{background:#fff;border:1px solid #ebebef;box-shadow:0 3px 6px #ebebef;width:40px;height:40px;position:absolute;right:-40px;display:block}
.styles_container__L63zz{border-radius:10px;border:1px solid #ebebef;width:100%;margin-bottom:10px;min-width:400px}.styles_container__L63zz:hover i{display:block}.styles_body__1DRG3{padding:24px;min-height:398px}.styles_label__DG7HP{font-size:14px;color:#354052;margin-bottom:8px}.styles_label__DG7HP span{color:#ff624c}.styles_inputsWrap__10IG3{display:flex;grid-gap:8px;gap:8px}.styles_inputWrap__3Jl37{width:100%}.styles_deleteTemplate__2VSxl{right:28px;top:-16px;position:absolute;display:none;transition:all .5s ease}.styles_deleteIcon__3jYdU{background:#fff;border:1px solid #ebebef;box-shadow:0 3px 6px #ebebef;width:40px;height:40px;position:absolute;right:-40px;display:block}
.styles_input__3Uyrw{width:100%;height:38px;border:1px solid #d7d8df;border-radius:4px;padding:8px 12px;outline:none;font-size:14px}.styles_input__3Uyrw:hover{border:1px solid rgba(53,64,82,.5)}
.styles_container__3e4SY{display:flex;grid-gap:8px;gap:8px}.styles_container__3e4SY:hover>span{visibility:visible}.styles_iconWrap__1ExlX{cursor:pointer;display:flex;align-items:center;visibility:hidden}
.styles_container__3uKnY{display:flex;grid-gap:8px;gap:8px}.styles_container__3uKnY:hover>span{visibility:visible}.styles_iconWrap__3tY2m{cursor:pointer;display:flex;align-items:center;visibility:hidden}
.styles_addButton__1_qGG{color:#1658f3;font-weight:700;font-size:14px;cursor:pointer;margin-top:8px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.styles_container__G4IgL{display:flex;flex-direction:column;grid-gap:8px;gap:8px}.styles_labels__3DiTb{display:flex;padding-right:28px}.styles_labels__3DiTb>span{font-size:12px;font-weight:700;width:50%}
.styles_button__3WtkH{height:24px;border-radius:4px;background-color:#354052;color:#fff;font-size:14px;display:flex;justify-content:center;align-items:center;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;padding:4px;cursor:pointer;grid-gap:2px;gap:2px}
.styles_container__3HNKn{position:relative}
.styles_container__3yLYx{position:relative}.styles_statusCode__2wGqI{font-size:16px;color:#006401;text-align:end;margin-bottom:12px}.styles_tabs__1WVjE{display:flex;flex-direction:column}.styles_activeTab__39o-M{font-size:14px;color:#1658f3;cursor:pointer;margin:4px}.styles_tab__2yytf{font-size:14px;color:#354052;cursor:pointer;margin:4px}.styles_body__1sJUv{display:flex;grid-gap:24px;gap:24px}.styles_input__w_3LV{border-radius:10px;border:1px solid #ebebef;flex:1 1;height:250px;padding:12px;overflow:auto}.styles_body__1sJUv{font-size:12px}
.styles_header__1RPb_{font-size:12px}.styles_header__1RPb_ span:last-child{color:#727a86}
.styles_container__17p5l{position:relative;width:100%;display:flex;justify-content:center;align-items:center;flex-direction:column;grid-gap:30px;gap:30px;padding-top:18px;height:265px}.styles_inputContainer__9nGlb{display:flex;grid-gap:8px;gap:8px}.styles_inputWrap__1-Xvn{width:150px}
.styles_container__2W1dU{padding-top:24px;min-height:360px}.styles_header__35vFa{display:flex;grid-gap:12px;gap:12px;position:relative;margin-bottom:24px;justify-content:space-between}.styles_header__35vFa::after{content:"";position:absolute;border-bottom:1px solid #ebebef;width:100%;bottom:0;z-index:-1}.styles_tabs__9Wg21{display:flex;grid-gap:12px;gap:12px;position:relative}.styles_tab__1TwPI{color:#354052;font-size:12px;font-weight:bold;padding:4px;cursor:pointer;white-space:nowrap}.styles_activeTab__Ze38F{color:#1658f3;font-size:12px;font-weight:bold;padding:4px;cursor:pointer;border-bottom:1px solid #1658f3;white-space:nowrap}.styles_button__y8Qqk{color:#1658f3;font-size:12px;font-weight:bold;cursor:pointer;padding:4px;white-space:nowrap}
.styles_profileImage__1bvXA{height:50px;width:50px;border-radius:50%;background-position:center;background-size:cover;background-repeat:no-repeat;position:relative}.styles_channelIcon__h0tm-{width:20px;height:20px;margin-left:5px;position:absolute;bottom:0;right:0}
.styles_container__2GWbS{font-size:12px;text-align:center;color:#b0b2c0;padding-top:16px}
.styles_container__1Vyuu{position:relative}.styles_id__4h16T{font-size:12px;color:#1658f3;position:absolute;background-color:#fff;padding:0 16px;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}
.styles_divider__111e5{border-bottom:1px solid #c7cad6;margin:40px 0}
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*.container, .backdrop, textarea {*/
/*width: 100%;*/
/*}*/
.paramsAnother {
  width: 400px !important;
  margin: 0;
}

.highlights,
textarea {
  padding: 10px;
  font: 14px/23px 'Open Sans', sans-serif;
  letter-spacing: 0px;
}

.container {
  display: flex;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-text-size-adjust: none;
}

.backdrop {
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  /*border-radius: 10px;*/
  background-color: #fff;
  overflow: auto;
  width: 100%;
  height: 140px;
  pointer-events: none;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
}

textarea {
  display: block;
  position: absolute;
  z-index: 2;
  margin: 0;
  height: 140px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  /*border-radius: 10px;*/
  color: #444;
  background-color: transparent;
  overflow: auto;
  resize: none;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  outline: none;
  width: 100%;
}

.styles_inputWrap__3HgUj{margin-bottom:32px;position:relative}.styles_description__2ATu7{font-size:12px;color:#727a86;font-family:Lato,sans-serif;margin:4px 0 0}.styles_errorAlert__1fqwU{color:#ff624c;margin-bottom:-10px;font-size:12px;position:absolute}
.styles_inputWrap__3o5H0{margin-bottom:32px;position:relative}.styles_description__2N6-L{font-size:12px;color:#354052;font-family:Lato,sans-serif;margin:4px 0 0}.styles_errorAlert__2Oqgx{color:#ff624c;margin-bottom:-10px;font-size:12px;position:absolute}
.styles_label__1FIZJ{font-size:14px;font-weight:400;margin:0 0 8px;color:#616581}
.styles_wrap__3G2MH{position:relative}.styles_container__73rbl{width:202px;height:148px;background-color:#fff;border-radius:10px;border:1px dashed #d7d8df;display:flex;align-items:center;justify-content:center}.styles_image__2Iaos{width:auto;height:auto;max-width:100%;max-height:100%;margin:auto}.styles_iconWrap__3U__X{width:50px;height:50px;background-color:#ebebef;border-radius:10px;display:flex;align-items:center;justify-content:center;margin:16px}.styles_label__3iTYF{margin:0;font-size:14px;color:#354052;font-weight:bold}.styles_label__3iTYF>span{color:#1658f3}.styles_uploadContainer__1SnQa{display:flex;align-items:center;justify-content:center;flex-direction:column}.styles_description__SEtja{font-size:14px;color:#b0b2c0}.styles_errorMessage__2J_el{font-size:12px;position:absolute;color:#ff624c;margin:0}
.styles_input__35shF{width:100%;height:100%;display:flex;justify-content:space-between}.styles_color__3xOTB{width:24px;height:24px}.styles_value__2B4po{font-size:14px;color:#616581}
.styles_container__e0aZV{width:360px;min-width:360px;height:540px;border-radius:10px;overflow:hidden;box-shadow:4px 4px 29px rgba(19,69,186,.0855129)}.styles_header__1op8t{width:100%;height:65px;padding:8px 24px 8px 16px}.styles_footer__1kie0{width:100%;height:65px;box-shadow:0px -1px 40px rgba(0,0,0,.07);display:flex;align-items:center;justify-content:space-between;padding:0 24px;background-color:#fff}.styles_body__1s10k{width:100%;height:410px;background-color:#fff}.styles_logo__2OGMG{height:50px;width:50px;border-radius:50%}.styles_nameWrap__2m4dX{display:flex;align-items:center}.styles_name__3pzpU{color:#fff;margin:0 16px}.styles_header__1op8t{display:flex;justify-content:space-between;align-items:center}.styles_inputPlaceholder__Hg4Po{margin:0;font-size:14px;color:#b0b2c0}.styles_messageLogo__3qOhG{height:32px;width:32px;border-radius:50%;margin:0 8px 0 16px;position:absolute;left:0}.styles_row__3jHEc{display:flex;align-items:flex-end;padding-left:56px;position:relative;grid-gap:8px;gap:8px}.styles_row__3jHEc:last-of-type{justify-content:flex-end}.styles_message1__lFrcp{border-radius:14px 14px 14px 8px;background-color:#f5f5f5;color:#373e41;font-size:14px;padding:5px 9px 15px 14px;margin:16px 18px 4px 0}.styles_message2__3ts9Z{border-radius:8px 14px 14px 14px;padding:10px 9px 10px 14px;background-color:#f5f5f5;color:#373e41;font-size:14px;margin:0 44px 0 0}.styles_quickReply__24s8d{font-size:14px;padding:8px 16px;border-radius:26px;border:1px solid;margin:16px 0}.styles_arrow__2p7vr{position:absolute;background-color:#fff;height:50px;width:25px;top:10px;right:-3px;box-shadow:0 0 20px rgba(51,51,51,.15);border-radius:50px 0 0 50px;display:flex;align-items:center;justify-content:center}.styles_reply__3ud_h{padding:10px 14px 10px 10px;border-radius:8px 14px 14px 14px;font-size:14px;color:#fff;margin:0 16px 0 0}
.styles_container__26nRJ{margin-bottom:40px}.styles_header__1HJNH{display:flex;align-items:center}.styles_header__1HJNH>h2{margin-bottom:0}.styles_body__pvU1Y{margin-top:40px;display:flex;grid-gap:40px;gap:40px}.styles_form__3W_kE{width:320px}.styles_paragraph__11_-X{font-size:14px;margin:12px 0 0;color:#b0b2c0}.styles_switchersContainer__M3nxv{margin-top:32px;margin-left:-12px}
.styles_container__Agc7X{display:flex;align-items:center}.styles_inputTitle__1k2OG{font-size:14px;color:#616581;font-weight:400;margin:0}
.styles_container__3C5At{display:block;position:relative;padding-left:40px;margin-bottom:12px;margin-top:16px;cursor:pointer;font-size:14px;-webkit-user-select:none;-ms-user-select:none;user-select:none;color:#616581}.styles_container__3C5At input{position:absolute;opacity:0;cursor:pointer;height:0;width:0}.styles_checkmark__2g8yv{position:absolute;top:0;left:0;height:23px;width:23px;background-color:#fff;border:1px solid #d7d8df;border-radius:2px}.styles_container__3C5At input:checked~.styles_checkmark__2g8yv{background-color:var(--theme-color);border:1px solid var(--theme-color)}.styles_checkmark__2g8yv:after{content:"";position:absolute;display:none}.styles_container__3C5At input:checked~.styles_checkmark__2g8yv:after{display:block}.styles_container__3C5At .styles_checkmark__2g8yv:after{left:7px;top:2px;width:7px;height:14px;border:solid #fff;border-width:0 2px 2px 0;-webkit-transform:rotate(45deg);transform:rotate(45deg)}
.styles_container__3hz2n{width:360px;min-width:360px;border-radius:10px;overflow:hidden;box-shadow:4px 4px 29px rgba(19,69,186,.0855129);height:100%}.styles_header__2NOJq{width:100%;height:130px;padding:24px;display:flex;justify-content:space-between}.styles_logo__1QoT3{height:60px;width:60px;min-width:60px;border-radius:50%}.styles_nameWrap__3oAm_{display:flex;align-items:center}.styles_titleWrap__2B41a{margin:0 0 0 24px;color:#fff;font-weight:100}.styles_title__13O05{font-size:20px;margin:0}.styles_greetingText__21Tyt{font-size:14px;margin:0}.styles_body__279f-{background-color:#fff;width:100%;padding:32px 40px;display:flex;flex-direction:column;align-items:flex-end}.styles_label__DHW7J{color:#616581;font-size:14px;width:100%}.styles_inputWrap__3iJqf{position:relative;width:100%}.styles_input__1ZNUD{margin-top:16px;border-radius:10px;border:1px solid #d7d8df;font-size:14px;color:#d7d8df;padding:12px 24px;width:100%}.styles_button__C56a9{margin-top:24px;padding:14px 24px;border-radius:10px;color:#fff;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;font-size:14px}.styles_buttonInput__1MQqv{position:absolute;right:0;top:15px;color:#fff;padding:13px 24px;border-radius:0 10px 10px 0;font-size:14px;border:1px solid}.styles_iconWrap__2BWla{width:14px}
.styles_header__s6JeD{display:flex;align-items:center}.styles_header__s6JeD>h2{margin-bottom:0}.styles_body__3Se9X{margin-top:40px;display:flex;grid-gap:40px;gap:40px}.styles_form__c9jEg{width:320px}
.styles_button__2PW_Y{cursor:pointer;color:#1658f3}.styles_editButton__c4_ew{padding:9px 13px;cursor:pointer;font-size:14px;display:flex;align-items:center;grid-gap:12px;gap:12px;width:160px}.styles_editButton__c4_ew:hover{background:#eef4fe}.styles_deleteButton__3tbcW{padding:9px 13px;cursor:pointer;font-size:14px;color:#ff624c;display:flex;align-items:center;grid-gap:12px;gap:12px;width:160px}.styles_deleteButton__3tbcW:hover{background:#eef4fe}
.styles_container__3vAJt{position:absolute;background-color:#fff;padding:40px 90px;outline:none;width:450px;border-radius:10px;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);overflow-y:auto;max-height:90%;display:flex;flex-direction:column;justify-content:center;align-items:center}.styles_title__1I8Ls{font-size:16px;color:#354052;font-weight:600;text-align:center;word-break:break-all}.styles_closeButton__ILZU4{position:absolute;top:24px;right:24px;cursor:pointer}.styles_deleteButton__eg4EW{font-size:14px;padding:10px 16px;color:#ff624c;cursor:pointer}.styles_buttonsWrap__13RVj{display:flex;grid-gap:8px;gap:8px}
.styles_inputTitle__1m9-D{font-size:14px;color:#3a3f62;font-weight:400;margin:24px 0 8px}.styles_container__2weFE{width:100%;display:flex;margin-bottom:8px}.styles_option__7hZcB{border:1px solid #c7cad6;display:flex;align-items:center;justify-content:center;height:45px;color:#616581;flex-grow:1;flex-basis:0;cursor:pointer}.styles_option__7hZcB:first-child{border-radius:10px 0 0 10px}.styles_option__7hZcB:last-child{border-radius:0 10px 10px 0}.styles_optionActive__2qOsj{border:1px solid #5a98f7;background-color:#5a98f7;display:flex;align-items:center;justify-content:center;height:45px;color:#fff;flex-grow:1;flex-basis:0;cursor:pointer}.styles_optionActive__2qOsj:first-child{border-radius:10px 0 0 10px}.styles_optionActive__2qOsj:last-child{border-radius:0 10px 10px 0}
.styles_container__v6esi{position:absolute;background-color:#fff;padding:24px 0 0;outline:none;width:600px;border-radius:10px;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);overflow-y:auto;max-height:90%}.styles_header__TD_Yv{padding:0 24px}.styles_closeButton__14eX8{position:absolute;top:24px;right:24px;cursor:pointer}.styles_title__W-xi9{font-weight:bold;font-size:24px;color:#354052;margin:0}.styles_body__2b2x5{padding:24px 40px 50px}.styles_footer__2Bu5T{border-top:1px solid #c7cad6;padding:24px;display:flex;grid-gap:8px;gap:8px;justify-content:flex-end}
.styles_body__3Hp7D{padding:0 40px 50px;height:calc(100vh - 300px);overflow:auto}.styles_tab__aQhmx{color:#354052;font-size:14px;padding:4px;cursor:pointer;white-space:nowrap}.styles_activeTab__OSLQf{color:#1658f3;font-size:14px;padding:4px;cursor:pointer;border-bottom:1px solid #1658f3;white-space:nowrap}.styles_tabs__2bQOv{margin:20px 24px 10px;display:flex;grid-gap:28px;gap:28px;position:relative}
.styles_container__2o_Kw{margin-bottom:40px}.styles_header__2Do3z{display:flex;align-items:center}.styles_header__2Do3z>h2{margin-bottom:0}.styles_body__3tAkZ{margin-top:40px;display:flex;flex-direction:column;grid-gap:32px;gap:32px}
.styles_snippet__2xgRv{padding:16px 24px;background-color:#fff;border-radius:10px;border:1px solid #c7cad6;font-size:14px;color:#354052;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;max-width:700px}
.styles_container__1ddA-{margin-bottom:40px}.styles_header__2fNza{display:flex;align-items:center}.styles_header__2fNza>h2{margin-bottom:0}.styles_body__1fhs4{margin-top:40px;display:flex;flex-direction:column}
.styles_header__2VknA{display:flex;align-items:center}.styles_header__2VknA>h2{margin-bottom:0}.styles_body__3n2io{margin-top:40px;display:flex;grid-gap:40px;gap:40px;padding-bottom:24px}.styles_form__1natK{width:400px}
.styles_container__Oy0pw{padding:55px 48px 120px 24px;border-right:1px solid rgba(53,64,82,.1);width:calc(100% - 398px);cursor:pointer}
.styles_filterItem__3rINm{height:28px;color:#3a3f62;cursor:pointer;display:flex;font-size:14px;align-items:center}.styles_filterItem__3rINm:hover{background-color:#ebebef}.styles_filterItem__3rINm>span{width:36px;text-align:center}
.styles_selectButton__1Kxdj{cursor:pointer;border-radius:10px;border:1px solid #ebebef;color:#616581;display:flex;align-items:center;margin-right:10px;font-size:12px;padding:5px 7px}.styles_selectButton__1Kxdj>span{padding-right:8px}.styles_popover__39Jkw{width:135px;height:125px;border-radius:4px;box-shadow:0 1px 5px rgba(19,69,186,.241904);padding:6px 0}.styles_divider__2BHGc{width:100%;border-bottom:1px solid rgba(53,64,82,.1)}
.styles_filterItem__1v68Y{height:28px;color:#3a3f62;cursor:pointer;display:flex;font-size:14px;align-items:center;padding:0 12px}.styles_filterItem__1v68Y:hover{background-color:#ebebef}.styles_filterItem__1v68Y>span{width:36px;text-align:center}.styles_popover__OmehJ{width:135px;height:40px;border-radius:4px;box-shadow:0 1px 5px rgba(19,69,186,.241904);padding:6px 0}
.styles_container__1aPLi{padding:12px 0}.styles_nameContainer__FVWRi{display:flex;align-items:center;justify-content:space-between}.styles_nameWrap___W9EV{font-size:14px;font-weight:bold;margin:0;display:inline}.styles_nameWrap___W9EV>span{font-weight:300;color:rgba(53,64,82,.5);margin-left:8px}.styles_score__1rXDV{color:#ff624c}.styles_comment__29hc3{padding-top:9px;font-size:14px;color:rgba(53,64,82,.7)}
.styles_container__2QcbN{width:398px}.styles_header__1fdwk{height:56px;border-bottom:1px solid rgba(53,64,82,.1);display:flex;align-items:center;justify-content:flex-end}.styles_selectButton__ZnZzH{cursor:pointer;border-radius:10px;border:1px solid #ebebef;color:#616581;display:flex;align-items:center;margin-right:10px;font-size:12px;padding:5px 7px}.styles_selectButton__ZnZzH>span{padding-right:8px}.styles_commentsContainer__eUuds{display:flex;flex-direction:column;padding:0 38px;height:440px;overflow:auto}.styles_loaderContainer__1Tyz9{display:flex;flex-direction:column;align-items:center;height:440px;justify-content:center}
.styles_header__3HXte{width:100%;display:flex;justify-content:space-between;padding:25px 40px;border-bottom:1px solid rgba(53,64,82,.1)}.styles_title__36L-j{font-size:18px;color:#3a3f62;margin:0;font-weight:700}.styles_rateWrap__2IvOz{display:inline-flex;color:rgba(53,64,82,.5);font-size:12px;margin:0;grid-gap:10px;gap:10px;align-items:baseline}.styles_rateWrap__2IvOz>span{font-weight:700;color:#3a3f62;font-size:14px}.styles_rateWrap__2IvOz>span>span{color:#ff624c}
.styles_container__1nT0G{background-color:#fff;border-radius:10px;box-shadow:4px 4px 29px rgba(19,69,186,.0855129)}.styles_chartWrap__pJMlW{display:flex;height:496px}
.styles_header__bP4a4{width:100%;display:flex;justify-content:space-between;padding:25px 40px;border-bottom:1px solid rgba(53,64,82,.1)}.styles_title__1knNC{font-size:18px;color:#3a3f62;margin:0;font-weight:700}.styles_rateWrap__2NyjH{display:inline;color:rgba(53,64,82,.5);font-size:12px;margin:0}.styles_rateWrap__2NyjH>span{font-weight:700;color:#3a3f62;font-size:14px}.styles_rateWrap__2NyjH>span>span{color:#ff624c}.styles_headerWrap__1jT6j{display:flex;align-items:center}
.styles_button__2hGL0{cursor:pointer;color:#1658f3}.styles_editButton__3QCD7{padding:9px 13px;cursor:pointer;font-size:14px;display:flex;align-items:center;grid-gap:12px;gap:12px;width:160px}.styles_editButton__3QCD7:hover{background:#eef4fe}.styles_deleteButton__1DsCd{padding:9px 13px;cursor:pointer;font-size:14px;color:#ff624c;display:flex;align-items:center;grid-gap:12px;gap:12px;width:160px}.styles_deleteButton__1DsCd:hover{background:#eef4fe}.styles_link__UsuX2{color:#1658f3;cursor:pointer}
.styles_title__1eZCm{font-size:14px;font-weight:400;margin:16px 0 8px;color:#3a3f62}.styles_errorMessage__3_F4s{font-size:12px;position:absolute;color:#ff624c;margin:0}
.styles_container__27GIP{display:flex;align-items:baseline;grid-gap:16px;gap:16px}
.styles_container__2uwFP{padding:40px;overflow:scroll;height:calc(100vh - 250px)}.styles_iconWrap__2aKs1{cursor:pointer;position:absolute;right:-20px;top:0;background-color:#fff;border:1px solid #ebebef;box-shadow:0 3px 6px #ebebef;padding:8px 10px;border-radius:50px}.styles_stepWrap__VpSMa{position:relative}
.styles_container__1W65X{position:absolute;background-color:#fff;padding:40px 90px;outline:none;width:450px;border-radius:10px;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);overflow-y:auto;max-height:90%;display:flex;flex-direction:column;justify-content:center;align-items:center}.styles_title__wMyjQ{font-size:16px;color:#354052;font-weight:600;text-align:center}.styles_closeButton__WD2JP{position:absolute;top:24px;right:24px;cursor:pointer}.styles_deleteButton__2INOh{font-size:14px;padding:10px 16px;color:#ff624c;cursor:pointer}.styles_buttonsWrap__1AKUg{display:flex;grid-gap:8px;gap:8px}
.styles_container__3DxvN{background-color:#fff;border-radius:10px;box-shadow:4px 4px 29px rgba(19,69,186,.0855129)}.styles_tableWrap__K9o6h{min-height:400px;display:flex}
.styles_container__2hkzB{padding-right:28px;display:flex;flex-direction:column;grid-gap:40px;gap:40px}.styles_containerLoader__1wzil{padding-right:28px;display:flex;flex-direction:column;grid-gap:40px;gap:40px;height:100%}
.styles_tooltip__gl9mD{background-color:#354052;color:#fff;border-radius:10px;font-size:14px;box-shadow:4px 4px 29px rgba(19,69,186,.0855129);max-width:360px}
.styles_container__20wuJ{height:100%}.styles_row__1-mTF{display:flex;justify-content:space-around;padding-top:10px;padding-left:44px}.styles_link__1YdkH{flex-basis:0;flex-grow:1;font-size:12px;color:#1658f3;text-align:center;cursor:pointer;padding:0 1px}.styles_atomName__3hnrE{flex-basis:0;flex-grow:1;font-size:12px;color:#616581;text-align:center;cursor:pointer;display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis}
.styles_container__z_Egd{display:flex;align-items:center;grid-gap:6px;gap:6px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.styles_button__2JV5C{cursor:pointer;padding:0 10px}.styles_input__1-Nqb{height:32px;min-width:32px;width:32px;outline:none;border:1px solid #d7d8df;border-radius:10px;color:#1658f3;text-align:center}.styles_inputHolder__3KDeK{display:flex;align-items:center;grid-gap:8px;gap:8px}
.styles_container__3CogM{display:flex;align-items:center;grid-gap:16px;gap:16px;justify-content:center;padding:8px 0;border-bottom:1px solid rgba(53,64,82,.1);border-top:1px solid rgba(53,64,82,.1)}.styles_title__2fzMW{font-size:14px;color:#3a3f62;font-weight:700}.styles_button__3Gtou{cursor:pointer}
.styles_header__3x6ZB{padding:20px 24px;display:flex;justify-content:space-between;align-items:center}.styles_button__uDFNQ{cursor:pointer}.styles_title__3xc43{color:#3a3f62;font-weight:700;font-size:18px}.styles_stepName__3nrrR{font-size:14px;color:#fff;background-color:#1658f3;padding:8px;border-radius:4px;cursor:pointer}.styles_titleWrap__WMDeD{display:flex;grid-gap:16px;gap:16px}
.styles_container__2t0jb{padding:0 16px;overflow:auto;height:100%}.styles_date__2xYDi{text-align:center;font-size:12px;font-weight:500;color:#3a3f62;margin:16px 0 20px;width:100%;display:flex;justify-content:center}.styles_bottomScrollElem__3zALM{float:left;clear:both}.styles_loaderContainer__cxNcL{height:100%;display:flex;justify-content:center;align-items:center}
.styles_container__2spWo{width:410px;background-color:#fff;display:flex;flex-direction:column;position:absolute;right:0;height:calc(100vh - 64px)}.styles_navigationContainer__3vcvO{display:flex;justify-content:center;flex-direction:column;align-items:center;padding-bottom:16px}
.styles_container__1wxlA{display:flex;width:100%;height:100%}.styles_chartContainer__1z2YI{padding-right:28px;display:flex;flex-direction:column;grid-gap:40px;gap:40px;padding-bottom:28px;width:100%;height:100%}.styles_chartContainerSelected__BDPSj{padding-right:28px;display:flex;flex-direction:column;grid-gap:40px;gap:40px;padding-bottom:28px;width:calc(100% - 400px);height:100%}
