@import '../../../../variables';

.header {
  display: flex;
  align-items: center;
  & > h2 {
    margin-bottom: 0;
  }
}

.body {
  margin-top: 40px;
  display: flex;
  gap: 40px;
  padding-bottom: 24px;
}

.form {
  width: 400px;
}
